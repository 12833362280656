<template>
  <div class="body">
    <div>
      <van-field rows="2" type="textarea" v-model="temp.content" maxlength="500" placeholder="发布班级里的故事吧..."
        show-word-limit class="font" />
    </div>
    <div class="upimg" style="width: 100%;">
      <van-uploader v-model="fileList" :accept="accept" multiple :max-count="upLength" :after-read="afterread"
        preview-size="25vw" />
    </div>
    <div class="down">
      <van-collapse v-model="activeNames">
        <van-collapse-item icon="friends-o" title="选择班级" name="1">
          <van-checkbox-group v-model="temp.classes_id" ref="checkboxGroup">
            <van-checkbox @click="checkAll">全选</van-checkbox>
            <van-checkbox v-for="(item, index) in classes" :key="index" :name="item.id">{{ item.name }}</van-checkbox>
          </van-checkbox-group>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="sure">
      <img src="@/assets/img/sure1.png" alt="" @click="submit" />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Momentsadd, upload, Getclasses } from "@/api/circle/addcircle";
export default {
  data() {
    return {
      fileList: [],
      activeNames: ["1"],
      result: [],
      classes: [],
      temp: {
        classes_id: [],
        file_type: 1,
        file_url: [],
        content: '',
      },
      oss_url: process.env.VUE_APP_OSS_URL,
      upLength: 9,
      accept: "",
    };
  },
  created() {
    this.getclass();
  },
  methods: {
    getclass() {
      Getclasses().then((res) => {
        // console.log(res);
        this.classes = res.data;
      });
    },
    showfa() {
      if (this.shoow == false) {
        this.shoow = true;
      } else if (this.shoow == true) {
        this.shoow = false;
      }
    },
    checkAll() {
    console.log(this.temp.classes_id.length);
    console.log(this.classes.length);
      if (this.temp.classes_id.length == this.classes.length) {
        this.$refs.checkboxGroup.toggleAll(false);
      } else {
        this.$refs.checkboxGroup.toggleAll(true);
      }
    },
    // 上传
    async afterread(file) {
      // console.log(file);
      // console.log();
      // upload(await this.$api.compressImg(file.file)).then((res) => {
      //     console.log(res);
      //     if (res.code == "200") {
      //         this.temp.file_url.push(res.data.url);
      //     }
      // });
    },
    // 提交
    async submit() {
      if (this.temp.classes_id.length == 0) {
        Toast.fail("请选择班级");
        return;
      }
      Toast.loading({
        message: '发布中...',
        forbidClick: true,
        duration: 'toast',
      });
      if (this.fileList.length > 0) {
        for (var i = 0; i < this.fileList.length; i++) {
          upload(await this.$api.compressImg(this.fileList[i].file)).then(
            (res) => {
              console.log(res);
              if (res.code == "200") {
                this.temp.file_url.push(res.data.url);

                if (this.temp.file_url.length == this.fileList.length) {
                  this.getadd();
                }
              } else {
                Toast.fail("网络异常！");
              }
            }
          );
        }
      }else{
        this.getadd();
      }

    },

    getadd() {
      Momentsadd(this.temp).then((res) => {
        console.log(res);
        Toast.clear();
        if (res.code == 200) {
          Toast.success("发布成功");
          setTimeout(() => {
            this.$router.push("/circle");
          }, 1000);
        } else {
          Toast.fail("发布失败");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  padding: 0.5rem;
  background-color: white;
}

/deep/ .van-field__control {
  height: 10rem;
}

/deep/ .van-field__word-limit {
  text-align: left;
  color: #999999;
  font-size: 1rem;
}

.upimg {
  padding: 0.5rem 1rem 1rem 1rem;
}

//预览图片大小
/deep/ .van-uploader__preview-image {
  width: 7rem;
  height: 7rem;
}

/deep/.van-uploader__upload {
  width: 7rem;
  height: 7rem;
}

.select {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 0 1rem;
  font-size: 1.2rem;

  img {
    width: 1.5rem;
    margin-bottom: -0.3rem;
    margin-right: 0.6rem;
  }

  .icon {
    margin-top: 0.3rem;
  }
}

/deep/ .van-checkbox {
  padding-bottom: 1.5rem;
  font-size: 1rem;
}

//外框图标
/deep/ .van-icon-friends-o:before {
  font-size: 1.4rem;
}

//外框标题
/deep/ .van-cell__title {
  font-size: 1rem;
}

/deep/ .van-checkbox__label {
  color: #666666;
}

.sure {
  height: 3rem;

  img {
    width: 80%;
    margin-left: 9%;
  }

  position: fixed;
  bottom: 10px;
}

/deep/ .van-uploader__preview-delete {
  background-color: #5380ff;
  border-radius: 20px;
}

/deep/ .van-uploader__preview-delete-icon {
  top: -1px;
  right: -1px;
}

/deep/ .van-uploader__preview-delete {
  margin: 0.2rem;
}
</style>
