import request from '@/utils/request'

// 添加班级圈
export function Momentsadd(data) {
  return request({
    url: '/mobile/moments/add',
    method: 'post',
    data: data
  })
}

// 获得班级
export function Getclasses(data) {
  return request({
    url: '/mobile/common/classes',
    method: 'post',
    data: data
  })
}

// 上传照片
export function upload(data) {
  // console.log(data, 'data');
  let formdata = new FormData()
  if(data.type == 'video/mp4'){
    
    formdata.set('video', data)
  }else{
    formdata.set('image', data)
  }
  return request({
    url: '/mobile/upload/upload',
    method: 'post',
    data: formdata
  })
}